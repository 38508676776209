
import React, { useEffect, useState, useRef } from 'react';
import { BsCalendar4Week } from 'react-icons/bs'
import ReactModal from 'react-modal';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { AiFillCloseCircle } from 'react-icons/ai'
import { db } from '../config';
import { useNavigate, useLocation } from 'react-router-dom';
import moment from 'moment/moment';
import "react-activity/dist/library.css";
import { Dots, Levels, Digital } from "react-activity";
import { toast } from 'wc-toast'
import { URL } from '../API';

const calendardb = db.ref('calendardb')
const bookingdb = db.ref('bookingdb')

const close_icon = <AiFillCloseCircle color='#599ab7' size={20} style={{ backgroundColor: '#c9e4f0', padding: 4, justifyContent: 'center', borderRadius: 4 }} />

const cal_icon = <BsCalendar4Week color='#599ab7' size={15} style={{ backgroundColor: '#c9e4f0', padding: 4, justifyContent: 'center', borderRadius: 4 }} />
const button_color = '#1a91c8'
// #f5f7fb
const top = '#e9f9fa'
const str = "We are working on behalf of the local authority, and need to check door(s), install new ones. As you were not available when we last visited, please fill in the details below to allow us to come back and visit again."

const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'Jul', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec']

const token = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIxIiwianRpIjoiN2QxMThiNGMxNTUyMzUzNDNmZWRlMTMwYTZlMDlmMDQzODJjYThhZGM1NmY3M2FhZTMyMzYzMWFmZTllNmE4ODJiNjAyOWJkYjliNDg3YzAiLCJpYXQiOjE2ODU1NTEzNjcuMTQzMDAxLCJuYmYiOjE2ODU1NTEzNjcuMTQzMDA0LCJleHAiOjE3MTcxNzM3NjcuMTQxMzU4LCJzdWIiOiIxMCIsInNjb3BlcyI6WyJzdHVkZW50Il19.bUS8n57seNqbXWLn-Qbkjesu_tsnTd4ZE0V6gcTP5ez8rnyln3rAY15iFHI5L_md4m4ucncYNyuEyughJq12Zb6uxDVZYGMLQWSQkP7ReBHUa7boYmxRgkBzTm-j718hVVfPBT-uvEL9_fQGtisT___btxWYbpDeyLbpr4CxLIvR7zVAzMrfJs1OwEog8C57M135RN87mAV0JF50J8AM9jA_Zait1TD94JTcEhwQHzmH0jEja_FA3pJi3Ok7CwhvjCINQZLjc_WsJb0aA6p48sKr8VLJVg5V2lb7E6iA68CkoxR-HwkLQ0uFhNl24o9tO1WZ8xTt-1ciC5T_GaxMaYeAH-QQotC-TgtYrVNymA3bEhMxVt9pEAW94QqEzjo8LJUtyumOzTANr1xNETvVZH9s3gfIKgiaPMIRZHxxH4fliT-BXCL-42cKAoJob1JQdjiOY80Ql49F12VMElRsNwXp2eLMVh4TZAszOtMFz-k_VxOvEuo5tknWbHhJqBfKlwZaL7l7xezuq_U_m8nZ0tLzgFb3uU8xdHcfRzEOdBDG-3vSsh6mZLrSxFCse2wn4en7QuQ7COp1H1UH2QX_EWUampbqqOvcNgoAuQ6QG3x5EN_DK77MEOmUob2_3nbnn7y6gLPv0VNozdHctfuhMH3xYIGj22ar3dLN98wMna0'

function Book() {

    const inpt1 = useRef(null);

    const navigate = useNavigate()
    const { state } = useLocation()

    const asset = state.asset;
    const post = state.post;
    const phone = state.phone;
    const last = state.last;

    console.log(last)

    function timings(str) {
        return new Date(str).getDate() + " " + months[new Date(str).getMonth()] + " " + moment(new Date(str)).format('YY').toString() + " , " + moment(new Date(str)).format('hh:mm a').toString()
    }

    const visit = timings(last)

    console.log(visit);

    const str1 = `We have found you! Our engineer tried to visit on ${visit} (last visit) and needed to Survey your Door.`


    const [modal, setModal] = useState(false)
    const [value, onChange] = useState(new Date());
    const [loading, setLoading] = useState(false)
    const [AM, setAm] = useState(false);
    const [dat, setDat] = useState(null)
    const [done, setDone] = useState(false)

    const [marked_date, setMarkedDate] = useState(null)

    useEffect(() => {
        if (dat && modal) {
            toast('Press now to submit your booking ')
            setModal(false)
        }
    }, [dat])

    function gettingCalendar() {
        calendardb.on('value', (snapshot) => {
            if (snapshot.val() != null) {
                let arr = Object.values(snapshot.val());
                setMarkedDate([...arr])
            }
            else {
                setMarkedDate([])
            }
        })
    }

    useEffect(() => {
        if (marked_date == null) {
            gettingCalendar()
        }
    })

    async function submit(id, timestamp, nmb, datt, caller) {
        if (dat != null) {
            let am_pm = AM ? 'AM' : 'PM'
            let uri = URL + "calendar-booking"
            fetch(uri, {
                method: 'POST',
                headers: {
                    Accept: 'application/form-data',
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + String(token)
                },
                body: JSON.stringify({
                    asset_no: asset,
                    date: timing(dat) + " , " + (AM ? 'AM' : 'PM'),
                    phone: phone
                })
            }).then((response) =>
                response.json().then(async (res) => {
                    if (res.error == false) {
                        toast('Your booking has been placed !')
                        const ress = await fetch(`https://us-central1-amsoftware-9c02d.cloudfunctions.net/app/send_message?id=${id}&timestamp=${timestamp}&nmb=${nmb}&dat=${datt}&caller=${String(caller)}`)
                        console.log(ress)
                        setDone(true)
                    }
                    else {
                        toast('Something is wrong !')
                    }
                })
            ).catch(e => {
                toast('Something is wrong !')
            })
        }
    }

    function updateCalendar() {
        if (dat != null) {
            setLoading(true)
            const day = moment(dat).format('YYYY-MM-DD')
            let ID = new Date(dat).getTime()
            let id = new Date().getTime()
            let tm = (AM ? 'AM' : 'PM')
            let nmb = AM ? 8 : 9
            let book_obj = { asset: asset, post: post, time: tm, ID: id, timestamp: ID, phone: phone, day: day, low_post: String(post).toLowerCase(), low_asset: String(asset).toLowerCase() }
            if (marked_date.find(x => x.id === day)) {
                toast('This date is unavailable now')
                setLoading(true)
            }

            else {
                bookingdb.child(String(id)).set(book_obj).then(() => {
                    setLoading(false)
                    submit(id, ID, nmb, day, phone)
                    // navigate('/')
                })
            }
        }
        else {
            toast('No date is selected !')
        }
    }

    const calendarStyle = (date) => {
        return {
            style: {
                backgroundColor: 'green',
                border: '1px solid gray',
                margin: 0,
                padding: 0
            }
        }
    }

    function timing(str) {
        return new Date(str).getDate() + " " + months[new Date(str).getMonth()] + " , " + new Date(str).getFullYear()
    }

    const [width, setWidth] = useState(window.innerWidth);

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }

    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);

    function isMobile() {
        return width <= 768;
    }

    return (
        <div style={{ height: '100%', display: 'flex', alignContent: 'center', flexDirection: 'column', backgroundColor: 'white', padding: 6 }}>
            <div style={{ height: 115, width: '100%', padding: 4 }}>
                <img src='https://firebasestorage.googleapis.com/v0/b/boomchat-d457f.appspot.com/o/logo.png?alt=media&token=b55f9897-f33f-4498-91bb-846f2ca0bad3&_gl=1*8okfq4*_ga*MTQ1Nzc5NzE2NC4xNjc5NjAxNzcz*_ga_CW55HF8NVT*MTY4NTU4MTg1OS4yLjEuMTY4NTU4MjAzOC4wLjAuMA' style={{ objectFit: 'contain', height: 100, width: 200, borderRadius: 10 }} />
            </div>
            <text style={{ textAlign: 'center', fontSize: (isMobile() ? 12 : 14), color: button_color }}>FIRE SAFETY IS IMPORTANT - REBOOK TODAY !</text>
            {!done && <text style={{ textAlign: 'center', letterSpacing: 1.01, fontSize: (isMobile() ? 27 : 36), color: '#363062', fontWeight: '900', marginTop: 7 }}>Sorry  we  missed  you  !</text>}
            {!done && <text style={{ textAlign: 'center', alignSelf: 'center', letterSpacing: 1.2, fontSize: 13, color: 'gray', fontWeight: '500', marginTop: 7, width: (isMobile() ? '93%' : '55%') }}>{str}</text>}
            {!done ? <div style={{ margin: '1em', padding: '1em', boxShadow: `1px 1px 9px gray`, padding: 15, borderRadius: 10, display: 'flex', flexDirection: 'column', marginTop: 16, width: (isMobile() ? '90%' : '50%'), alignSelf: 'center', justifyContent: 'space-around', alignItems: 'center', backgroundColor: 'white', borderRadius: 10, height: (isMobile() ? '90%' : '55%') }}>
                <text style={{ width: (isMobile() ? '95%' : '60%'), marginTop: 15, textAlign: 'center', alignSelf: 'center', fontSize: 13, fontWeight: '700' }}>{str1}</text>
                <text style={{ width: (isMobile() ? '95%' : '60%'), marginTop: 10, textAlign: 'center', alignSelf: 'center', fontSize: 12, fontWeight: '500', color: 'gray' }}>Please select a date/time below for when we can next visit.</text>
                <div style={{ margin: '1em', padding: '1em', boxShadow: `1px 1px 7px gray`, alignItems: 'center', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', padding: 6, marginTop: 15, marginBottom: 15, height: 36, borderRadius: 8, padding: 4, width: (isMobile() ? '90%' : '75%'), alignSelf: 'center' }}>
                    <text onClick={() => setModal(true)} style={{ cursor: 'pointer', color: 'black', fontSize: 13, fontWeight: '600' }}>{dat ? timing(dat) : 'Select date'}</text>
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <input checked={AM} type='checkbox' onChange={() => setAm(true)} style={{ height: 12, width: 12, borderRadius: 6, backgroundColor: 'whitesmoke' }} />
                        <text style={{ marginLeft: 6, fontWeight: '-moz-initial' }}>AM</text>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <input checked={!AM} type='checkbox' onChange={() => setAm(false)} style={{ height: 12, width: 12, borderRadius: 6, backgroundColor: 'whitesmoke' }} />
                        <text style={{ marginLeft: 6, fontWeight: '-moz-initial' }}>PM</text>
                    </div>
                    <div onClick={() => setModal(true)} style={{ cursor: 'pointer', justifyContent: 'center' }}>{cal_icon}</div>
                </div>
                <div onClick={() => updateCalendar()} style={{ cursor: 'pointer', display: 'flex', boxShadow: `1px 1px 1px gray`, marginBottom: 10, width: 120, borderRadius: 8, padding: 10, justifyContent: 'center', backgroundColor: button_color, alignItems: 'center', alignContent: 'center' }}>
                    <text style={{ color: 'white', textAlign: 'center' }}>Submit</text>
                </div>
            </div> : null}

            {done ? <div style={{ width: (isMobile() ? '93%' : '50%'), padding: 15, borderRadius: 10, margin: '1em', padding: '1em', boxShadow: `1px 1px 9px gray`, display: 'flex', flexDirection: 'column', marginTop: 16, alignSelf: 'center', justifyContent: 'space-around', alignItems: 'center', backgroundColor: 'white', borderRadius: 10, height: '35%' }}>
                <text style={{ width: (isMobile() ? '90%' : '70%'), marginTop: 15, textAlign: 'center', alignSelf: 'center', fontSize: 17, fontWeight: '700' }}>
                    Thank you!
                </text>
                <text style={{ width: (isMobile() ? '90%' : '70%'), marginTop: 15, textAlign: 'center', alignSelf: 'center', fontSize: 17, fontWeight: '700' }}>
                    Your Booking is now confirmed for :
                </text>
                <text style={{ width: (isMobile() ? '90%' : '70%'), marginTop: 15, textAlign: 'center', alignSelf: 'center', fontSize: 17, fontWeight: '700' }}>
                    {(timing(dat)) + "," + (AM ? 'AM' : 'PM')}
                </text>
            </div> : null}

            {done ? <div style={{ width: (isMobile() ? '93%' : '50%'), padding: 15, borderRadius: 10, margin: '1em', padding: '1em', boxShadow: `1px 1px 9px gray`, display: 'flex', flexDirection: 'column', marginTop: 16, alignSelf: 'center', justifyContent: 'space-around', alignItems: 'center', backgroundColor: 'white', borderRadius: 10, height: '35%' }}>
                <text style={{ width: (isMobile() ? '95%' : '60%'), marginTop: 15, textAlign: 'center', alignSelf: 'center', fontSize: 14, fontWeight: '700' }}>Please note AM times are from 8am - 11.30am
                    PM times are from Midday - 5pm
                </text>
                <text style={{ width: (isMobile() ? '95%' : '60%'), marginTop: 10, textAlign: 'center', alignSelf: 'center', fontSize: 13, fontWeight: '600' }}>If you need to change your booking please call us
                    Monday - Friday 9am - 5pm on
                    0207 112 9189</text>
            </div> : null}

            <ReactModal
                style={{ overlay: { display: 'flex', alignSelf: 'center', justifyContent: 'flex-end', alignContent: 'flex-end', alignItems: 'center' }, content: { position: 'absolute', top: '2%', left: (isMobile() ? '1%' : '20%'), display: 'flex', backgroundColor: 'white', width: (isMobile() ? '95%' : '60%'), alignSelf: 'flex-end', alignItems: 'flex-end', flexDirection: 'column', borderRadius: 16, height: '95%' } }}
                isOpen={modal}
                onRequestClose={() => setModal(false)}
                closeTimeoutMS={100}
                shouldFocusAfterRender={true}
                shouldCloseOnOverlayClick={true}
                shouldCloseOnEsc={true}
                shouldReturnFocusAfterClose={true}
                preventScroll={true}
            >

                <div style={{ display: 'flex', alignContent: 'center', width: (isMobile() ? '95%' : '65%'), alignSelf: 'center', flexDirection: 'column', padding: 10, height: '95%', justifyContent: 'center', alignItems: 'center' }}>
                    <div onClick={() => setModal(false)} style={{ marginRight: 20, marginBottom: 15, alignSelf: 'flex-end', cursor: 'pointer', padding: 4 }}>
                        {close_icon}
                    </div>
                    {marked_date != null &&
                        <div style={{ height: 365, padding: 10, display: 'flex', justifyContent: 'center', alignContent: 'center', flexDirection: 'column', alignSelf: 'center', width: isMobile() ? '99%' : '90%', alignItems: 'center' }}>

                            <div style={{ marginRight: 25, display: 'flex', display: 'flex', justifyContent: 'space-between', width: isMobile() ? '95%' : '80%', alignSelf: 'center' }}>
                                <text style={{ textAlign: 'left', fontSize: 17, fontWeight: '600' }}>Booking</text>
                                <div style={{ flexDirection: 'column', display: 'flex', alignContent: 'center', alignItems: 'center' }}>
                                    <div style={{ backgroundColor: '#FFB736', width: 100, display: 'flex', justifyContent: 'center' }}><text style={{ color: 'white', fontWeight: '700', textAlign: 'center' }}>u</text></div>
                                    <text>Not Available</text>
                                </div>
                            </div>

                            <div style={{ marginRight: 25, display: 'flex', maxHeight: 400, alignSelf: 'center', alignItems: 'center', alignContent: 'center', justifyContent: 'center', width: isMobile() ? '95%' : '80%', alignSelf: 'center' }}>

                                <Calendar
                                    style={{ height: 400, alignSelf: 'center' }}
                                    minDate={
                                        new Date()
                                    }
                                    tileContent={({ date, view }) => {
                                        if (marked_date.find(x => x.id === moment(date).format('YYYY-MM-DD'))) {
                                            return (<div style={{ backgroundColor: '#FFB736' }}><text style={{ color: 'white', fontWeight: '700' }}>u</text></div>)
                                        }
                                    }}
                                    onChange={(value, event) => { if (marked_date.find(x => x.id === moment(value).format('YYYY-MM-DD'))) { toast('This date is unavailable') } else setDat(value) }}
                                    dayPropGetter={calendarStyle}
                                />
                            </div>
                        </div>
                    }

                </div>

            </ReactModal>
            <wc-toast></wc-toast>
            {loading && <div style={{ position: 'absolute', top: '35%', left: '44%', backgroundColor: 'white', padding: 10 }}><Levels size={35} /></div>}

        </div>
    );
}

export default Book;
