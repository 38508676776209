
import {
  BrowserRouter,
  Routes, //replaces "Switch" used till v5
  Route,
  HashRouter
} from "react-router-dom";
import React, { useState, useEffect } from 'react';

import App from './screens/App';

import Book from './screens/Book';
import Cancell from "./screens/Cancell";
import Invalid from "./screens/Invalid";
import { Navigate } from 'react-router-dom';

export default function Main() {


  return (
    <div className="App">
      <HashRouter>
        <Routes>
          <Route path="*" element={<App />} />
          <Route path="/" element={<App />} />
          <Route path="/Book" element={<Book />} />
          <Route path="/Invalid" element={<Invalid />} />
          <Route path="/Cancel" element={<Cancell />} />
        </Routes>
      </HashRouter>
    </div>
  );
}
