// Import the functions you need from the SDKs you need

//import firebase from "firebase";
// Import the functions you need from the SDKs you need
import firebase from 'firebase/compat/app';
//import firebase from "firebase";
import "firebase/compat/database";
import "firebase/compat/storage";
import "firebase/compat/auth";
import "firebase/compat/firestore"
import "firebase/compat/messaging"
//import "firebase/compat/messaging"

const config = {
    apiKey: "AIzaSyAesYF7QZbeDDrIkIWmtAXNoRfjWoycAWA",
    authDomain: "amsoftware-9c02d.firebaseapp.com",
    databaseURL: "https://amsoftware-9c02d-default-rtdb.firebaseio.com",
    projectId: "amsoftware-9c02d",
    storageBucket: "amsoftware-9c02d.appspot.com",
    messagingSenderId: "245618196385",
    appId: "1:245618196385:web:b25a5ae7d2ca455d1c15e0",
    measurementId: "G-4DPRRQHJLV"
  };
  

firebase.initializeApp(config);
// const db=firebase.database();
export const db = firebase.database();
export const storage = firebase.storage();
export const auth = firebase.auth();
export const firestore = firebase.firestore()
export const firebases = firebase;