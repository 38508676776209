
import React, { useEffect, useState, useRef } from 'react';
import { BsCalendar4Week } from 'react-icons/bs'
import ReactModal from 'react-modal';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { AiFillCloseCircle } from 'react-icons/ai'
import { db } from '../config';
import { useNavigate, useLocation } from 'react-router-dom';
import moment from 'moment/moment';
import "react-activity/dist/library.css";
import { Dots, Levels, Digital } from "react-activity";
import { toast } from 'wc-toast'
import { URL } from '../API';

const calendardb = db.ref('calendardb')
const bookingdb = db.ref('bookingdb')

const close_icon = <AiFillCloseCircle color='#599ab7' size={20} style={{ backgroundColor: '#c9e4f0', padding: 4, justifyContent: 'center', borderRadius: 4 }} />

const cal_icon = <BsCalendar4Week color='#599ab7' size={15} style={{ backgroundColor: '#c9e4f0', padding: 4, justifyContent: 'center', borderRadius: 4 }} />
const button_color = '#1a91c8'
// #f5f7fb
const top = '#e9f9fa'
const str = "We are working on behalf of the local authority, and need to check door(s), install new ones. As you were not available when we last visited, please fill in the details below to allow us to come back and visit again."

const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'Jul', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec']

//const token = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIxIiwianRpIjoiN2QxMThiNGMxNTUyMzUzNDNmZWRlMTMwYTZlMDlmMDQzODJjYThhZGM1NmY3M2FhZTMyMzYzMWFmZTllNmE4ODJiNjAyOWJkYjliNDg3YzAiLCJpYXQiOjE2ODU1NTEzNjcuMTQzMDAxLCJuYmYiOjE2ODU1NTEzNjcuMTQzMDA0LCJleHAiOjE3MTcxNzM3NjcuMTQxMzU4LCJzdWIiOiIxMCIsInNjb3BlcyI6WyJzdHVkZW50Il19.bUS8n57seNqbXWLn-Qbkjesu_tsnTd4ZE0V6gcTP5ez8rnyln3rAY15iFHI5L_md4m4ucncYNyuEyughJq12Zb6uxDVZYGMLQWSQkP7ReBHUa7boYmxRgkBzTm-j718hVVfPBT-uvEL9_fQGtisT___btxWYbpDeyLbpr4CxLIvR7zVAzMrfJs1OwEog8C57M135RN87mAV0JF50J8AM9jA_Zait1TD94JTcEhwQHzmH0jEja_FA3pJi3Ok7CwhvjCINQZLjc_WsJb0aA6p48sKr8VLJVg5V2lb7E6iA68CkoxR-HwkLQ0uFhNl24o9tO1WZ8xTt-1ciC5T_GaxMaYeAH-QQotC-TgtYrVNymA3bEhMxVt9pEAW94QqEzjo8LJUtyumOzTANr1xNETvVZH9s3gfIKgiaPMIRZHxxH4fliT-BXCL-42cKAoJob1JQdjiOY80Ql49F12VMElRsNwXp2eLMVh4TZAszOtMFz-k_VxOvEuo5tknWbHhJqBfKlwZaL7l7xezuq_U_m8nZ0tLzgFb3uU8xdHcfRzEOdBDG-3vSsh6mZLrSxFCse2wn4en7QuQ7COp1H1UH2QX_EWUampbqqOvcNgoAuQ6QG3x5EN_DK77MEOmUob2_3nbnn7y6gLPv0VNozdHctfuhMH3xYIGj22ar3dLN98wMna0'

function Invalid() {

    const [width, setWidth] = useState(window.innerWidth);

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }

    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);

    function isMobile() {
        return width <= 768;
    }

    return (
        <div style={{ height: '100%', display: 'flex', alignContent: 'center', flexDirection: 'column', backgroundColor: 'white', padding: 6 }}>
            <div style={{ height: 115, width: '100%', padding: 4 }}>
                <img src='https://firebasestorage.googleapis.com/v0/b/boomchat-d457f.appspot.com/o/logo.png?alt=media&token=b55f9897-f33f-4498-91bb-846f2ca0bad3&_gl=1*8okfq4*_ga*MTQ1Nzc5NzE2NC4xNjc5NjAxNzcz*_ga_CW55HF8NVT*MTY4NTU4MTg1OS4yLjEuMTY4NTU4MjAzOC4wLjAuMA' style={{ objectFit: 'contain', height: 100, width: 200, borderRadius: 10 }} />
            </div>
            <text style={{ textAlign: 'center', fontSize: (isMobile() ? 12 : 14), color: button_color }}>FIRE SAFETY IS IMPORTANT - REBOOK TODAY !</text>

            <div style={{ width: (isMobile() ? '93%' : '50%'), padding: 15, borderRadius: 10, margin: '1em', padding: '1em', boxShadow: `1px 1px 9px gray`, display: 'flex', flexDirection: 'column', marginTop: 16, alignSelf: 'center', justifyContent: 'space-around', alignItems: 'center', backgroundColor: 'white', borderRadius: 10, height: '35%' }}>

                <text style={{ width: (isMobile() ? '90%' : '70%'), marginTop: 15, textAlign: 'center', alignSelf: 'center', fontSize: 17, fontWeight: '700' }}>
                    Sorry this link is now invalid, please try again.
                </text>

            </div>

            <wc-toast></wc-toast>

        </div>
    );
}

export default Invalid;
