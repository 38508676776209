
import React, { useState, useEffect, useRef } from 'react';
import { URL } from '../API';
import { Dots, Levels, Digital } from "react-activity";
import { toast } from 'wc-toast'
import { useNavigate } from 'react-router-dom';
import "react-activity/dist/library.css";
import { db } from '../config';
const token = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIxIiwianRpIjoiN2QxMThiNGMxNTUyMzUzNDNmZWRlMTMwYTZlMDlmMDQzODJjYThhZGM1NmY3M2FhZTMyMzYzMWFmZTllNmE4ODJiNjAyOWJkYjliNDg3YzAiLCJpYXQiOjE2ODU1NTEzNjcuMTQzMDAxLCJuYmYiOjE2ODU1NTEzNjcuMTQzMDA0LCJleHAiOjE3MTcxNzM3NjcuMTQxMzU4LCJzdWIiOiIxMCIsInNjb3BlcyI6WyJzdHVkZW50Il19.bUS8n57seNqbXWLn-Qbkjesu_tsnTd4ZE0V6gcTP5ez8rnyln3rAY15iFHI5L_md4m4ucncYNyuEyughJq12Zb6uxDVZYGMLQWSQkP7ReBHUa7boYmxRgkBzTm-j718hVVfPBT-uvEL9_fQGtisT___btxWYbpDeyLbpr4CxLIvR7zVAzMrfJs1OwEog8C57M135RN87mAV0JF50J8AM9jA_Zait1TD94JTcEhwQHzmH0jEja_FA3pJi3Ok7CwhvjCINQZLjc_WsJb0aA6p48sKr8VLJVg5V2lb7E6iA68CkoxR-HwkLQ0uFhNl24o9tO1WZ8xTt-1ciC5T_GaxMaYeAH-QQotC-TgtYrVNymA3bEhMxVt9pEAW94QqEzjo8LJUtyumOzTANr1xNETvVZH9s3gfIKgiaPMIRZHxxH4fliT-BXCL-42cKAoJob1JQdjiOY80Ql49F12VMElRsNwXp2eLMVh4TZAszOtMFz-k_VxOvEuo5tknWbHhJqBfKlwZaL7l7xezuq_U_m8nZ0tLzgFb3uU8xdHcfRzEOdBDG-3vSsh6mZLrSxFCse2wn4en7QuQ7COp1H1UH2QX_EWUampbqqOvcNgoAuQ6QG3x5EN_DK77MEOmUob2_3nbnn7y6gLPv0VNozdHctfuhMH3xYIGj22ar3dLN98wMna0'
const button_color = '#1a91c8'
// #f5f7fb
const top = '#e9f9fa'
const str = "We are working on behalf of the local authority, and need to check door(s), install new ones. As you were not available when we last visited, please fill in the details below to allow us to come back and visit again."

const bookingdb = db.ref('bookingdb')

function App() {

  const path = (window.location.href)

  console.log(path)

  useEffect(() => {
    async function checking() {
      if (path.includes('cancel')) {
        console.log(path)
        let id = -1, timestamp = -1, am_pm = -1;
        let arr = path.split('/')
        if (arr.length < 4) {
          toast('This link is not valid')
          navigate('/Invalid')
        }
        for (let i = arr.length - 1; i >= 0; i--) {
          if (arr[i] == 8 || arr[i] == 9) {
            am_pm = arr[i];
            timestamp = arr[i - 1]
            id = arr[i - 2];
            break;
          }
        }
        console.log(arr);
        const exis = await bookingdb.child(String(id)).once('value')
        if (exis.val() == null) {
          toast('This link is not valid')
          navigate('/Invalid')
        }
        else if (exis.val() != null) {
          if (exis.val().timestamp == timestamp && (am_pm == 8 || am_pm == 9)) {
            navigate('/Cancel', { state: { id: id, timestamp: timestamp, am_pm: am_pm } })
          }
          else {
            toast('This link is not valid')
            navigate('/Invalid')
          }
        }
        else navigate('/Cancel', { state: { id: id, timestamp: timestamp, am_pm: am_pm } })
      }
    }
    checking()
  },[path])

  let inpt1 = useRef();
  let inpt2 = useRef();
  let inpt3 = useRef();

  const navigate = useNavigate()

  const [asset, setAsset] = useState('')
  const [post, setPost] = useState('')
  const [asset1, setAsset1] = useState('')
  const [asset2, setAsset2] = useState('')
  const [asset3, setAsset3] = useState('')
  const [phone, setPhone] = useState('')
  const [loading, setLoading] = useState(false)

  const [width, setWidth] = useState(window.innerWidth);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }

  useEffect(() => {
    if (asset1.length == 3) {
      inpt2.current?.focus()
    }
  }, [asset1])

  useEffect(() => {
    if (asset2.length == 3) {
      inpt3.current?.focus()
    }
  }, [asset2])

  useEffect(() => {
    if (asset1 != '' && asset2 != '' && asset3 != '') {
      let str = 'AMS-' + asset1 + '-' + asset2 + '-' + asset3
      setAsset(str)
    }
  }, [asset1, asset2, asset3])

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    }
  }, []);

  function isMobile() {
    return width <= 768;
  }

  function submit() {
    let uri = URL + "client-callback"
    if (asset != '' && post != '') {
      setLoading(true)
      fetch(uri, {
        method: 'POST',
        headers: {
          Accept: 'application/form-data',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + String(token)
        },
        body: JSON.stringify({
          asset_no: asset,
          post_code: post
        })
      }).then((response) =>
        response.json().then((res) => {
          if (res.error == false) {
            console.log(res);
            if (res.data.message != undefined && res.data.message == 'No data found') { setLoading(false); toast('We could not find you , please check the data inputted and try again !') }
            else if (res.data.asset_no != undefined) { setLoading(false); 
              toast('We have found your result');
              navigate('/Book', { state: { last: res.data.attemp_time, asset: asset, post: post, phone:"+44"+phone } }) }
          }
          else {
            toast('We didnot find your datas')
            setLoading(false)
          }
        })
      ).catch(e => {
        toast('Something is wrong !')
      })
    }
  }

  return (
    <div style={{ height: '100%', display: 'flex', alignContent: 'center', flexDirection: 'column', backgroundColor: 'white', padding: 6 }}>
      <div style={{ height: 115, width: '100%', padding: 4 }}>
        <img src='https://firebasestorage.googleapis.com/v0/b/boomchat-d457f.appspot.com/o/logo.png?alt=media&token=b55f9897-f33f-4498-91bb-846f2ca0bad3&_gl=1*8okfq4*_ga*MTQ1Nzc5NzE2NC4xNjc5NjAxNzcz*_ga_CW55HF8NVT*MTY4NTU4MTg1OS4yLjEuMTY4NTU4MjAzOC4wLjAuMA' style={{ objectFit: 'contain', height: 100, width: 200, borderRadius: 10 }} />
      </div>
      <text style={{ textAlign: 'center', fontSize: isMobile() ? 12 : 15, color: button_color }}>FIRE SAFETY IS IMPORTANT - REBOOK TODAY !</text>
      <text style={{ textAlign: 'center', letterSpacing: 1.01, fontSize: (isMobile() ? 27 : 36), color: '#363062', fontWeight: '900' }}>Sorry  we  missed  you  !</text>
      <text style={{ textAlign: 'center', alignSelf: 'center', mletterSpacing: 1.5, fontSize: 13, color: 'gray', fontWeight: '500', width: (isMobile() ? '93%' : '55%'), marginTop: 4 }}>{str}</text>

      <div style={{ borderRadius: 10, margin: '1em', padding: '1em', boxShadow: `1px 1px 9px gray`, display: 'flex', flexDirection: 'column', marginTop: 16, width: (isMobile() ? '90%' : '50%'), alignSelf: 'center', justifyContent: 'space-around', alignItems: 'center', backgroundColor: 'white', borderRadius: 10, height: (isMobile() ? '90%' : '55%'), padding: 10 }}>
        <text style={{ marginTop: 15, textAlign: 'left', marginLeft: (isMobile() ? '5%' : '20%'), alignSelf: 'flex-start', fontSize: 15 }}>Asset No.</text>
        <div style={{ borderRadius: 10, margin: '1em', padding: '1em', flexDirection: 'row', display: 'flex', width: (isMobile() ? '90%' : '60%'), boxShadow: `1px 1px 9px gray`, height: 20, alignItems: 'center', alignContent: 'center', marginBottom: 10, }}>
          <text style={{ color: 'black', fontWeight: '500', height: 20, fontSize: 13, marginTop: isMobile() ? 6 : 1 }}>AMS</text>
          <input ref={inpt1} value={asset1} placeholder=' xxx ' onChange={(e) => { if (e.target.value.length <= 3) setAsset1(e.target.value) }} style={{ color: 'black', fontSize: 13, outline: 'none', height: 20, borderStyle: 'none', alignSelf: 'center', width: 25, marginLeft: 3 }} />
          <text style={{ color: 'black', fontWeight: '500', height: 20, fontSize: 13, marginTop: isMobile() ? 6 : 1 }}>- </text>
          <input ref={inpt2} value={asset2} placeholder=' xxx ' onChange={(e) => { if (e.target.value.length <= 3) setAsset2(e.target.value) }} style={{ color: 'black', fontSize: 13, outline: 'none', height: 20, borderStyle: 'none', alignSelf: 'center', width: 25, marginLeft: 3 }} />
          <text style={{ color: 'black', fontWeight: '500', height: 20, fontSize: 13, marginTop: isMobile() ? 6 : 1 }}>- </text>
          <input ref={inpt3} value={asset3} placeholder=' xxx ' onChange={(e) => { if (e.target.value.length <= 3) setAsset3(e.target.value) }} style={{ color: 'black', fontSize: 13, outline: 'none', height: 20, borderStyle: 'none', alignSelf: 'center', width: 25, marginLeft: 3 }} />
        </div>
        <text style={{ textAlign: 'left', marginLeft: (isMobile() ? '5%' : '20%'), alignSelf: 'flex-start', fontSize: 15 }}>Postcode</text>
        <div style={{ borderRadius: 10, margin: '1em', padding: '1em', flexDirection: 'row', display: 'flex', width: (isMobile() ? '90%' : '60%'), boxShadow: `1px 1px 9px gray`, height: 20, marginBottom: 10, alignItems: 'center', alignContent: 'center' }}>
          <input value={post} placeholder=' Enter post code ' onChange={(e) => setPost(e.target.value)} style={{ marginTop: 2, outline: 'none', height: 20, borderRadius: 8, padding: 4, borderStyle: 'none', alignSelf: 'center', width: '90%' }} />
        </div>
        <text style={{ textAlign: 'left', marginLeft: (isMobile() ? '5%' : '20%'), alignSelf: 'flex-start', fontSize: 15 }}>Mobile No.</text>
        <div style={{ borderRadius: 10, flexDirection: 'row', margin: '1em', padding: '1em', display: 'flex', width: (isMobile() ? '90%' : '60%'), boxShadow: `1px 1px 9px gray`, height: 20, alignItems: 'center', alignContent: 'center', marginBottom: 10 }}>
          <text style={{ fontSize: 14, fontWeight: '600',width:'10%' }}>+44</text>
          <input value={phone} placeholder='Enter mobile no. ' onChange={(e) => setPhone(e.target.value)} style={{ marginTop: 2, outline: 'none', height: 20, borderRadius: 8, padding: 4, borderStyle: 'none', alignSelf: 'center', width: '80%' }} />
        </div>
        <div onClick={() => submit()} style={{ cursor: 'pointer', display: 'flex', boxShadow: `1px 1px 1px gray`, marginBottom: 10, width: 120, borderRadius: 8, padding: 10, justifyContent: 'center', backgroundColor: button_color, alignItems: 'center', alignContent: 'center' }}>
          <text style={{ color: 'white', textAlign: 'center' }}>Submit</text>
        </div>
      </div>

      <wc-toast></wc-toast>
      {loading && <div style={{ position: 'absolute', top: '35%', left: '44%', backgroundColor: 'white', padding: 10 }}><Levels size={35} /></div>}
    </div>
  );
}

export default App;
